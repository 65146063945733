import { useContext } from 'react'
import NextImage from 'next/image'
import { SpacerProps } from '../../../siteBuilder/blocks/core/Spacer/Spacer'

import { SiteBuilderContext } from '../../../siteBuilder/renderer/context/SiteBuilderContext'

import Image, { RoundedImageOptions } from '../../../siteBuilder/blocks/core/Image/Image'
import { pickDataAttrs } from '../../../siteBuilder/utils/dataAttributes'
import { contentfulLoader } from '../../../siteBuilder/blocks/core/Image/contentfulLoader'
import { ImagePositionProps } from '../../../siteBuilder/blocks/core/Image/ImagePosition'
import { getRoundedOption } from '@/siteBuilder/blocks/core/Image/utils/getRoundedOption'

export type ImageProps = {
  src: string
  href?: string
  alt?: string
  decorative?: boolean
  spacer?: SpacerProps
  width: number
  height: number
  position?: ImagePositionProps
  removeWhiteSpace?: boolean
  rounded?: RoundedImageOptions
  blurDataURL?: string
  placeholder?: 'blur' | 'empty'
  priority?: boolean
  styles?: React.CSSProperties
  futureImage?: boolean
}

const BBImage = (props: ImageProps) => {
  const { isWebpSupported } = useContext(SiteBuilderContext)
  const webpLoader = contentfulLoader(isWebpSupported)

  const { blurDataURL, placeholder, src, styles, alt, decorative, width, height, futureImage, rounded, priority } =
    props

  const dataAttrs = pickDataAttrs(props)

  const RoundedImageStyle = {
    borderRadius: getRoundedOption(rounded),
  }

  const blogImageStyle = {
    maxWidth: '100%',
    maxHeight: '700px',
    width: '100%',
    height: 'auto',
    ...RoundedImageStyle,
  }

  const defaultImageStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: width,
    ...RoundedImageStyle,
  }

  if (futureImage) {
    return (
      <NextImage
        priority={priority}
        loader={webpLoader}
        src={src}
        alt={decorative ? '' : alt}
        width={width}
        height={height}
        style={styles ? styles : blogImageStyle}
        blurDataURL={webpLoader({ src: blurDataURL, width })}
        placeholder={placeholder}
        {...dataAttrs}
      />
    )
  }

  return <Image {...props} />
}

export default BBImage
