import { IRichTextFields } from 'contentful-types'

import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import { CONTENTFUL_TEXT_COLOUR_MAPPER, isVariantColor } from '@/siteBuilder/utils/text/mappers'
import RichText, { RichTextProps } from './RichText'

// TODO: add colors
const ContentfulRichText = withContentful<IRichTextFields, RichTextProps>(RichText, {
  spacer: mapSpacer,
  paragraphSize: (props): RichTextProps['paragraphSize'] =>
    props.fields.paragraphSize === 'medium' ? null : props.fields.paragraphSize,
  colour: ({ fields: { colour } }) => {
    if (isVariantColor(colour)) {
      return colour
    }
    return CONTENTFUL_TEXT_COLOUR_MAPPER[colour]
  },

  align: (props): RichTextProps['align'] => props.fields.align?.toLowerCase() as Lowercase<IRichTextFields['align']>,
  linkColour: (props): RichTextProps['linkColour'] =>
    props.fields.linkColour?.toLowerCase() as Lowercase<IRichTextFields['linkColour']>,
  listIcon: (props): RichTextProps['listIcon'] => {
    const { iconName, iconColor, iconSize } = props.fields
    return { iconName, iconColor, iconSize }
  },
})

export default ContentfulRichText
